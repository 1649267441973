.amountProgress {
  @apply bg-plain-darker rounded-full;
  height: 10px;
  transform-origin: left;
  animation: emerging 0.5s ease-out 0s forwards;
}

@keyframes emerging {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(100%);
  }
}
