@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: blackscale;
  @apply font-sans;
}

html {
  overflow: hidden;
  overscroll-behavior: none;
}

body {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}

input[type='text'],
input[type='email'],
input[type='password'] {
  font-size: 18px;
}

.wrapper {
  height: 100%;
}
.wrapper.with-footer {
  height: calc(100% - 63px);
  margin-top: -63px;
}
