.nav {
  @apply absolute bottom-0 left-0 w-full;
  display: flex;
  flex-direction: row;

  align-items: center;
  height: calc(63px + constant(safe-area-inset-bottom, 0px));
  height: calc(63px + env(safe-area-inset-bottom, 0px));
}

.humberger {
  @apply relative;
  height: 28px;
  width: 28px;
  margin-bottom: 6px;
  display: inline-block;

  div {
    @apply absolute left-0 bg-white inline-block;
    height: 1.5px;
    width: 28px;

    &:nth-of-type(1) {
      bottom: 20px;
    }
    &:nth-of-type(2) {
      bottom: 10px;
      opacity: 1;
    }
    &:nth-of-type(3) {
      bottom: 0;
    }
  }
}

.navItem {
  @apply flex flex-col w-full h-full items-center justify-center bg-plain-darker text-white relative;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  img {
    @apply stroke-current text-white;
    height: 36px;
  }

  svg {
    height: 36px;
    rect {
      @apply stroke-white;
    }
    path {
      @apply fill-white;
    }
  }

  span {
    @apply block mt-1 text-xs;
  }

  &.badge {
    figure {
      @apply relative;
      &:after {
        @apply rounded-full;
        content: '';
        display: block;
        position: absolute;
        top: 2px;
        right: -4px;
        width: 14px;
        height: 14px;
        background-color: #d30808;
      }
    }
  }

  &.active {
    @apply bg-white text-plain-normal;
    svg {
      rect {
        @apply stroke-plain-normal;
      }
      path {
        @apply fill-plain-normal;
      }
    }

    .humberger {
      @apply relative;
      height: 28px;
      width: 28px;
      margin-bottom: 6px;
      display: inline-block;

      div {
        @apply bg-plain-normal;

        &:nth-of-type(1) {
          bottom: 10px;
          transform: rotate(45deg);
        }
        &:nth-of-type(2) {
          opacity: 0;
        }
        &:nth-of-type(3) {
          bottom: 10px;
          transform: rotate(-45deg);
        }
      }
    }
  }
}

.menu {
  @apply absolute bottom-[63px] block w-full bg-slate-50 px-2;
  bottom: calc(63px + constant(safe-area-inset-bottom, 0px));
  bottom: calc(63px + env(safe-area-inset-bottom, 0px));
}
